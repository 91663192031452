<template>
  <a-modal :maskClosable="false" title="选择商品" style="top: 8px;" width="80%" v-model="visible" @ok="toSubmit">
    <div class="query">
      <a-form-model :labelCol="{span: 7}" :wrapperCol="{span:17}">
        <a-row :gutter="4">
          <!-- <a-col  :md="8" >
            <a-form-model-item label="商品ID">
              <a-input v-model.trim="queryParam.id" placeholder="商品ID" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col> -->
          <a-col :md="8">
            <a-form-model-item label="商品名称">
              <a-input v-model.trim="queryParam.name" placeholder="商品名称" :max-length="50" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" >
            <a-form-model-item :labelCol="{span: 0}" :wrapperCol="{span:22,offset:1}">
              <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
              <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="table">
      <a-table :data-source="tableData" bordered rowKey="id" :pagination="page" @change="p=>{page=p;toSearch(2)}"
        :row-selection="{fixed: true,type: 'checkbox',selectedRowKeys:selectedRowKeys,onChange:
                                (keys,rows)=>{selectedRowKeys=keys;selectedRows= rows}}"
        :customRow="record=>{return{on:{click:e=>{let index=this.selectedRowKeys.indexOf(record.id);index>=0?selectedRowKeys.splice(index, 1):selectedRowKeys.push(record.id);index>=0?selectedRows.splice(index, 1):selectedRows.push(record);}}}}">
        <a-table-column title="CNC商号" data-index="cncCompany" :width="150" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="商品名称" data-index="name" :width="200" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="总成号" data-index="assemblyNumber" :width="200" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="规格型号" data-index="specStr" :width="150" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="图片" data-index="imgUrl" :width="100" :ellipsis="true" align="center">
          <template slot-scope="text, record">
            <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>
          </template>
        </a-table-column>
        <a-table-column title="市场价" data-index="price" width="100" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="标准开票价（D价）" data-index="sellPrice" width="100" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="库存" data-index="realQuantity" width="100" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="最低起订量" data-index="minQuantity" width="100" :ellipsis="true" align="left">
        </a-table-column>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
  const qs = require('qs')
  export default {
    data() {
      return {
        queryParam: {},
        resetQueryParam: null,
        tableData: [],
        loading: false,
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
          flagUp: 1
        },
        selectedRowKeys: [],
        selectedRows: [],
        visible: false
      }
    },
    components: {},
    methods: {
      show(ids) {
        this.visible = true
        this.selectedRowKeys = ids
        this.toSearch(1)
      },
      toSearch(type) {
        if (type == 1) {
          this.resetQueryParam ? this.queryParam = Object.assign({}, this.resetQueryParam) : this.resetQueryParam =
            Object.assign({}, this.queryParam)
        }
        if (type != 2) {
          this.selectedRowKeys = []
          this.selectedRows = []
        }
        this.page.pageNumber = type == 2 ? this.page.current : this.page.current = 1
        const postData = Object.assign({}, this.queryParam,this.page)
        this.axios.get(`/api/product/system/goods/list?${qs.stringify(postData)}`).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        }).catch(err => {})
      },
      toSubmit() {
        this.axios.post(`/api/product/system/goods/getProductGoodsList`, this.selectedRowKeys).then(res => {
          this.$emit('select', res.body)
          this.visible = false
        }).catch(err => {})
      },
    },
    created() {

    },

  }
</script>


<style lang="less" scoped>
</style>
